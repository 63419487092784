var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-slide-y-reverse-transition", [
    _c(
      "div",
      { staticClass: "history-compare-footer", attrs: { mode: "in" } },
      [
        _c(
          "div",
          { staticClass: "text-caption" },
          [_c("cs-square", { attrs: { variant: "added" } }), _vm._v(" Added")],
          1
        ),
        _c(
          "div",
          { staticClass: "text-caption mx-4" },
          [
            _c("cs-square", { attrs: { variant: "deleted" } }),
            _vm._v(" Deleted"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-caption" },
          [
            _c("cs-square", { attrs: { variant: "changed" } }),
            _vm._v(" Changed"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }