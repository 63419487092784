
import Vue from 'vue'
import {
  setShowedCompareStates,
  getShowedCompareStates,
  setCurrentCamera,
  // drawCompareView,
} from '@/store/modelViewer.store'
import { CsSquare } from '@consteel/csuetify'

export default Vue.extend({
  name: 'HistoryCompareFooter',
  components: {
    CsSquare, //TODO
  },
  computed: {
    selectedCompareStates: {
      get() {
        return getShowedCompareStates()
      },
      set(newVal: string[]) {
        setShowedCompareStates(newVal)
        setCurrentCamera()
        // drawCompareView(true, false) //TODO
      },
    },
  },
})
